import FileGrid from "./FileGrid";

const FileGridComponent = ({ files, onDelete, onUpdate, FileCustomComponent, onRestore }) => {
    return (
        <div className="row">
            {
                files?.map((file, i) => {
                    return (
                        FileCustomComponent ?
                            <FileCustomComponent onRestore={onRestore} onUpdate={onUpdate} onDelete={onDelete} file={file} key={i} />
                            :
                            <FileGrid
                                onDelete={onDelete}
                                file={file}
                                key={i}
                                onUpdate={onUpdate}
                            />
                    )
                })
            }
        </div>
    )
}

export default FileGridComponent;